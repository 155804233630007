async function maybeLoad() {
  const maybeSpecialOffersInclude = document.querySelector(
    "script[src*='special-offers.js']"
  );

  if (!maybeSpecialOffersInclude) {
    const { default: bootstrapUSO } = await import(
      /* webpackChunkName: "special-offers" */
      "./special-offers"
    );

    await bootstrapUSO(window);
  } else {
    // eslint-disable-next-line no-console
    console.log(
      "%c Ultimate Special Offers: script tag for special-offers.js included inline.",
      "color: #777"
    );
  }
}

if (document.readyState !== "loading") {
  maybeLoad();
} else {
  document.addEventListener("readystatechange", maybeLoad, { once: true });
}
